import React, { useEffect } from 'react'
import Layout from '../components/layout'
import { navigate } from "gatsby"

const IndexPage = () => {
  useEffect(() => {
    navigate('/curriculum-editor')
  }, [])

  return <Layout>
    <h1>Curriculum Dashboard - 0.0.1</h1>
  </Layout>
}

export default IndexPage
